import { useState } from 'react'
import { Box, Circle, Flex, Text, VStack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { SettingsCard } from './SettingsCard'
import { useFetchIntegrationSettings } from '../../../hooks/palantir/useFetchIntegrationSettings'
import { HorizontalTab, HorizontalTabs } from '../../ui-kit/HorizontalTabs'

export const IntegrationSettings = ({ customerUUID }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [eventsPresent, setEventsPresent] = useState([])
  const defaultEvents = [
    {
      label: 'Product page view',
      value: 'ec.detail',
    },
    {
      label: 'Add to cart',
      value: 'ec.add',
    },
    {
      label: 'Checkout',
      value: 'ec.checkout',
    },
    {
      label: 'Purchase',
      value: 'ec.purchase',
    },
  ]

  const { isFetching: isFetchLoading } = useFetchIntegrationSettings(
    { customerUUID },
    {
      onSuccess(data) {
        setEventsPresent(data.event_types)
      },
    }
  )

  const circleColor = (eventType) =>
    eventsPresent.some((event) => event.includes(eventType))
      ? 'success'
      : 'danger'

  return (
    <Box h="100%" overflow="hidden">
      <HorizontalTabs
        activeIndex={activeTab}
        onActiveIndexChange={setActiveTab}
      >
        <HorizontalTab label="Revend Integration">
          <SettingsCard
            title="Revend Integration"
            subtitle="Default events being tracked"
            isLoading={isFetchLoading}
          >
            <VStack spacing={4} overflowY="auto" align="start" mt={6}>
              {defaultEvents.map((defaultEvent) => (
                <Flex gap="4" key={defaultEvent.value}>
                  <Circle size="20px" bg={circleColor(defaultEvent.value)} />
                  <Text fontSize="xs" fontWeight="700" mb="0">
                    {defaultEvent.label}
                  </Text>
                </Flex>
              ))}
            </VStack>
          </SettingsCard>
        </HorizontalTab>
      </HorizontalTabs>
    </Box>
  )
}

IntegrationSettings.propTypes = {
  customerUUID: PropTypes.string.isRequired,
}
