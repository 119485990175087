import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useAuthToken } from '../store/useAuthToken'
import { mutateNotificationSettings } from '../../services/api/palantir'

/**
 * Sends the notification settings payload to Palantir.
 *
 *
 * @typedef {Object} IncidentConfiguration
 * @property {string} uuid
 * @property {string} customer_uuid
 * @property {string} event_type
 * @property {?number} warmup_time
 * @property {string} updated_at
 * @property {?boolean} notify_only_when_test_fails
 * @property {?number} sliding_window_length_hours
 * @property {?number} incident_sensitivity
 * @property {?boolean} notify_when_below_minimum
 * @property {?boolean} notify_when_below_average
 * @property {?boolean} notify_when_above_maximum
 * @property {?boolean} notify_when_above_average
 *
 * @typedef {Object.<string, string>} EventLabel
 *
 * @typedef {Object} RelevanceConfiguration
 * @property {?number} revenue_above
 * @property {?number} duration_above
 * @property {?Array<string>} event_types_included
 * @property {boolean} is_revenue_above_active
 * @property {boolean} is_duration_above_active
 * @property {boolean} is_events_included_active
 *
 * @typedef {Object} NotificationSettingsPayload
 * @property {string} customerUUID
 * @property {?Array<string>} trackedEvents
 * @property {?EventLabel} eventLabels
 * @property {?IncidentConfiguration} incidentConfigurations
 * @property {?RelevanceConfiguration} relevance
 * @property {?Array<string>} ipBlacklist
 * @property {?Record.<string, { icon: string, name: string, lower_bound: number, upper_bound: number }>} priorityTiers
 * */

export const useMutateNotificationSettings = () => {
  const token = useAuthToken()
  const client = useQueryClient()

  return useMutation({
    mutationKey: ['notificationSettings', token],
    /**
     * @param {NotificationSettingsPayload} payload
     */
    mutationFn: (payload) => mutateNotificationSettings(token, payload),
    async onSuccess(_, { customerUUID }) {
      await client.invalidateQueries({
        queryKey: ['notificationSettings', { customerUUID }],
      })
    },
  })
}
