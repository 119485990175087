import { useState } from 'react'
import PropTypes from 'prop-types'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Box } from '@chakra-ui/react'
import { useFetchNotificationSettings } from '../../../../hooks/palantir'
import { useMutateNotificationSettings } from '../../../../hooks/palantir/useMutateNotificationSettings'
import { RelevanceConfigurationTab } from './RelevanceTab'
import { EventsToMonitorTab } from './EventsToMonitorTab'
import { EventsNameTab } from './EventsNameTab'
import { IpBlacklistConfigurationTab } from './IpBlacklistConfigurationTab'
import { HorizontalTab, HorizontalTabs } from '../../../ui-kit/HorizontalTabs'
import { EventTypeTab } from './eventTypeTab/EventTypeTab'
import { NotificationsTab } from './NotificationsTab'
import { PriorityTiersTab } from './PriorityTiersTab/PriorityTiersTab'

export const NotificationSettings = ({ customerUUID }) => {
  const [activeTab, setActiveTab] = useState(0)
  const [notificationConfig, setNotificationConfig] = useState({
    event_types: [],
    tracked_events: [],
    event_labels: {},
    incident_configurations: {},
    relevance: {},
    ip_blacklist: [],
    emails: [],
  })

  const { isFetching: isFetchLoading } = useFetchNotificationSettings(
    { customerUUID },
    {
      onSuccess(data) {
        setNotificationConfig(data)
      },
    }
  )
  const flags = useFlags()
  const { mutate, isLoading: mutateLoading } = useMutateNotificationSettings()
  const loading = isFetchLoading || mutateLoading

  const saveTrackedEvents = (trackedEvents) => {
    mutate({
      customerUUID,
      trackedEvents,
    })
    setNotificationConfig({
      ...notificationConfig,
      tracked_events: trackedEvents,
    })
  }

  const saveEventsLabels = (eventLabels) => {
    mutate({
      customerUUID,
      eventLabels,
    })
    setNotificationConfig({ ...notificationConfig, event_labels: eventLabels })
  }

  const saveRelevanceConfiguration = (configuration) => {
    mutate({
      customerUUID,
      relevance: configuration,
    })
    setNotificationConfig({ ...notificationConfig, relevance: configuration })
  }

  const saveIpBlacklistConfiguration = (configuration) => {
    mutate({
      customerUUID,
      ipBlacklist: configuration,
    })
    setNotificationConfig({
      ...notificationConfig,
      ip_blacklist: configuration,
    })
  }

  return (
    <Box maxH="100%" h="100%">
      <HorizontalTabs
        activeIndex={activeTab}
        onActiveIndexChange={setActiveTab}
      >
        <HorizontalTab label="Notifications">
          <NotificationsTab />
        </HorizontalTab>
        <HorizontalTab label="Events to monitor">
          <EventsToMonitorTab
            eventTypes={notificationConfig.event_types}
            trackedEvents={
              // Should show all events as checked if none are configured
              notificationConfig.tracked_events?.length
                ? notificationConfig.tracked_events
                : notificationConfig.event_types
            }
            isLoading={loading}
            saveConfiguration={saveTrackedEvents}
          />
        </HorizontalTab>
        <HorizontalTab label="Event type">
          <EventTypeTab />
        </HorizontalTab>
        <HorizontalTab label="Events name">
          <EventsNameTab
            eventTypes={notificationConfig.event_types}
            eventLabels={notificationConfig.event_labels}
            isLoading={loading}
            saveConfiguration={saveEventsLabels}
          />
        </HorizontalTab>
        {flags.numenorPriorityConfig && (
          <HorizontalTab label="Priority configuration">
            <PriorityTiersTab />
          </HorizontalTab>
        )}
        {flags.relevanceConfiguration && (
          <HorizontalTab label="Relevance configuration">
            <RelevanceConfigurationTab
              eventTypes={notificationConfig.event_types}
              relevance={notificationConfig.relevance}
              isLoading={loading}
              saveConfiguration={saveRelevanceConfiguration}
            />
          </HorizontalTab>
        )}
        <HorizontalTab label="IP Blacklist">
          <IpBlacklistConfigurationTab
            ipBlacklist={notificationConfig.ip_blacklist}
            saveConfiguration={saveIpBlacklistConfiguration}
            isLoading={loading}
          />
        </HorizontalTab>
      </HorizontalTabs>
    </Box>
  )
}

NotificationSettings.propTypes = {
  customerUUID: PropTypes.string.isRequired,
}
